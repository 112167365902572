import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { LuFileSpreadsheet } from "react-icons/lu";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import MasterTable from "../../components/MasterTable";
import { media } from "../../utils/media";
import { TailSpin } from "react-loader-spinner";
import { onDownloadExcelClickCT } from "../../utils/clevertap";
import { getDownloadsClientExcel } from "../../centralizedAPICalls/service";
import { useDispatch, useSelector } from "react-redux";
import { fetchNewExcelsData, selectnewexcelData, setNewExcelsData } from "../../utils/redux/slices";

const getNameHeader = (count) => {
  return (
    <CountTextWrap>
      <HeaderNameDiv>Name</HeaderNameDiv>
      <CountText>({count} {count <= 1 ? "Result" : "Results"})</CountText>
    </CountTextWrap>
  );
};

const DownloadPage = () => {
  const [columnsData, setColumnsData] = useState([
    {
      key: "name",
      value: getNameHeader(0),
      sorting: false,
      range_filter: false,
      value_filter: false,
      min_width: "250px",
    },
    {
      key: "download",
      value: "",
      sorting: false,
      range_filter: false,
      value_filter: false,
      min_width: "180px",
      align: "center",
    },
  ]);
  const [rowsData, setRowsData] = useState([]);
  const [styledRowsData, setStyledRowsData] = useState([]);
  const [excelLoaderState, setExcelLoaderState] = useState({});
  const IndustryDict = JSON.parse(window.localStorage.getItem("IndustryDict"));
  const is_athena=process.env.REACT_APP_API_ENDPOINT.includes("athena");
  const dispatch = useDispatch()
  const newexcelaccessData = useSelector(selectnewexcelData);

  
  const downloadExcel = async (value, index) => {
    setExcelLoaderState((prevState) => ({ ...prevState, [index]: true }));

    let client_id = window.localStorage.getItem("clientID");
    let report_name = value.name;
    let report_id = value.id;
    const industry_id = IndustryDict[report_id]?.industry_id
    let url = `${process.env.REACT_APP_API_ENDPOINT}/newexcel/?client_id=${client_id}&report_id=${report_id}&is_athena=${is_athena}&industry_id=${industry_id}&email_id=${window.localStorage.getItem('email')}`;
 
    const MAX_ATTEMPTS = Number(window.localStorage.getItem("clientID"))===53 && is_athena===true?0:3;
    const RETRY_DELAY = 20000;
  
    for (let attempt = 1; attempt <= MAX_ATTEMPTS; attempt++) {
      try {
        console.log(`Attempt ${attempt} to fetch the file...`);
        const response = await fetch(url, {
          method: "GET",
          cache: "no-store",
          headers: {
            "Content-Type": "application/json",
          },
        });
  
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
  
        const res = await response.json();
        if (res["excel_link"]) {
          const link = document.createElement("a");
          link.href = res["excel_link"];
          link.download = report_name;
          link.click();
  
          setExcelLoaderState((prevState) => ({ ...prevState, [index]: false }));
          onDownloadExcelClickCT(report_name, report_id, client_id.key, true);
          return;
        }
      } catch (error) {
        console.error(`Error on attempt ${attempt}:`, error);
        if (attempt === MAX_ATTEMPTS) {
          console.log("Max attempts reached. Falling back to the existing flow...");
        } else {
          await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY));
        }
      }
    }
  
    fetch(url, {
      method: "GET",
      cache: "no-store",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        const link = document.createElement("a");
        link.href = res["excel_link"];
        link.download = report_name;
        link.click();
  
        setExcelLoaderState((prevState) => ({ ...prevState, [index]: false }));
        onDownloadExcelClickCT(report_name, report_id, client_id.key, true);
      })
      .catch((error) => {
        setExcelLoaderState((prevState) => ({ ...prevState, [index]: false }));
        onDownloadExcelClickCT(report_name, report_id, client_id.key, false);
        console.error(error);
      });
  };

  useEffect(() => {
    dispatch(fetchNewExcelsData());
  }, [dispatch]);

  useEffect(() => {
    if (newexcelaccessData.length > 0) {
      const formattedData = newexcelaccessData.map((item, index) => ({
        player: {
          name: item.name,
          isSubscribed: index % 2 === 0,
          bookMarkValue: 0,
          sortingValue: index * 100,
          id: item.id,
        },
      }));
      setRowsData(formattedData);
    }
  }, [newexcelaccessData]); 

  const sortRows = useCallback(() => {
    const sortedData = rowsData.map((row, index) => ({
      name: {
        value: <NameCell   
        onClick={() => {
          const url = `/Report3/?val=${row.player.name}&key=${row.player.id}&filter=${row.player.filter}&filter_value=${row.player.filter_value}`;
          window.open(url, "_blank");
        }}>{row.player.name}</NameCell>,
        sortingValue: row.player.sortingValue,
      },
      download: {
        value: (
          <ExcelButtonWrapper>
            <ExcelButton onClick={() => downloadExcel(row.player, index)}>
              {excelLoaderState[index] ? (
                <LoaderWrapper>
                  <TailSpin
                    height="20"
                    width="20"
                    color="#0066CC"
                    ariaLabel="loading"
                    radius="1"
                    visible={true}
                  />
                  <LoadingText>Preparing...</LoadingText>
                </LoaderWrapper>
              ) : (
                <>
                  <LuFileSpreadsheet size={18} />
                  <span>Download Excel</span>
                </>
              )}
            </ExcelButton>
          </ExcelButtonWrapper>
        ),
      },
    }));

    setStyledRowsData(sortedData);
  }, [excelLoaderState, rowsData]);


  useEffect(() => {
    if (rowsData.length > 0) {
      setColumnsData((prevColumnsData) => [
        {
          ...prevColumnsData[0],
          value: getNameHeader(rowsData.length), 
        },
        ...prevColumnsData.slice(1),
      ]);
      sortRows();
    }
  }, [rowsData, sortRows]);

  return (
    <PageWrapper>
      <Header />
      <GridWraper>
        <MasterTable
          columnsData={columnsData}
          rowsData={styledRowsData}
          headerBackground="#FFFFFF"
          autoCellHeight={true}
          toolGap={-4}
        />
      </GridWraper>
      <Footer />
    </PageWrapper>
  );
};

export default DownloadPage;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #eef9ff;
  min-height: 100vh;
`;

const CountTextWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const HeaderNameDiv = styled.div`
  font-size: 16px;
  ${media.small`
  font-size: 12px;
  `}
`;

const CountText = styled.div`
  color: #444444;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding-left: 10px;
  ${media.small`
  font-size: 12px;
  padding-left: 5px;
  line-height: 12px;
  `}
`;

const GridWraper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 3.5vw 0px;
  margin-bottom: 10px;
  min-height: 85vh;
  ${media.small`
     margin-bottom: 10px;
    `}
`;
const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const LoadingText = styled.span`
  font-size: 14px;
  color: #64748b;
`;

const ExcelButton = styled.button`
  display: flex;
  align-items: center;
  gap: 10px;
  border: none;
  border-radius: 8px;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.2s ease;
  cursor: pointer;

  &:hover {
    background-color: #e2e8f0;
  }

  &:active {
    transform: scale(0.98);
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
`;

const ExcelButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const NameCell = styled.div`
  font-weight: 500;
  color: #0099FF;
  font-size: 14px;
  padding: 4px 0;
  text-decoration: none;
  cursor:pointer;

  &:hover, 
  &:focus, 
  &:active {
    text-decoration: none; 
  }
`;
