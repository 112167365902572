import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useState } from 'react'
import CustomForm from '../components/CustomForm/CustomForm'
import MyContext from '../utils/contexts/MyContext';
import { Navigate, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { onNewsletterClickCT, onUserLoginCT } from "../utils/clevertap";
import { media } from '../utils/media';

const Otp3 = () => {
    const [formData, setFormData] = useState({});
    const [wrongOTP, setWrongOTP] = useState(false);
    const { treeData, setTreeData } = React.useContext(MyContext);
    const [signIn, setSignIn] = useState(false);
    const search = useLocation().search;
    const url_source = new URLSearchParams(search).get('source')
    const url_email = new URLSearchParams(search).get('email')
    const url_newsletter_name = new URLSearchParams(search).get('email')
    const url_newsletter_month = new URLSearchParams(search).get('email')
    const url_newsletter_user = new URLSearchParams(search).get('email')
    const url_newsletter_email = new URLSearchParams(search).get('email')

    const source = url_source || window.localStorage.getItem('source') || "";
    const email = url_email || window.localStorage.getItem('email') || "";
    const newsletter_name = url_newsletter_name || window.localStorage.getItem('newsletter_name') || "";
    const newsletter_month = url_newsletter_month || window.localStorage.getItem('newsletter_month') || "";
    const newsletter_user = url_newsletter_user || window.localStorage.getItem('newsletter_user') || "";
    const newsletter_email = url_newsletter_email || window.localStorage.getItem('newsletter_email') || "";
    const navigate = useNavigate();

    useEffect(() => {
      const timeoutId = setTimeout(() => {
        if (
          newsletter_name &&
          newsletter_month &&
          newsletter_user &&
          newsletter_email
        ) {
          onNewsletterClickCT(
            newsletter_name,
            newsletter_month,
            "clicked",
            newsletter_email,
            "Otp Page"
          );
        }
      }, 1000);
      window.localStorage.setItem('email', email)
      return () => {
        window.localStorage.removeItem("newsletter_name");
        window.localStorage.removeItem("newsletter_month");
        window.localStorage.removeItem("newsletter_user");
        window.localStorage.removeItem("newsletter_email");
        clearTimeout(timeoutId);
      };
    }, []);

    const fields = [
        {  type: 'text', style: { backgroundColor: 'white' } ,      placeholder: "OTP",
      }, // Customize 'email' field background color
      ];

      const handleChange = (name, value) => {
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: value,
        }));
      };



      function findFirstSubscribedNode(data) {
        for (let node of data) {
            if (node.subscribed && node.label!=='Sectors') {
                return node;
            }
            
            const result = findFirstSubscribedNode(node.nodes);
            if (result) {
                return result;
            }
        }
        
        return null;
    }

      const handleSubmit = (e) => {
        // Send the formData to the API or perform further processing here
        e.preventDefault();
        console.log(formData);
        let otp = formData['OTP'] || formData[Object.keys(formData)[0]];
        const uploadData = new FormData();
        let client_id = window.localStorage.getItem("clientID")
        uploadData.append('email', email);
        uploadData.append('OTP', otp);
        fetch(`${process.env.REACT_APP_API_ENDPOINT}/authorise/login/`, {
            method: 'POST',
            body: uploadData
          }).then(data => data.json())
          .then( data => {
            if (data.token){
              // fetch(`${process.env.REACT_APP_API_ENDPOINT}/newreports/?rep_id=9&client_id=${client_id}`, {
              //   method:'GET',
              //   headers:{
              //     'Content-Type': 'application/json',
              //   },
              // })
              // .then(res=>res.json())
              // .then(
              //   res=>{
              //     setTreeData(res)
              //     console.log(res)
              //     setSignIn(true);
              //   }
              // )
              setSignIn(true);
                window.localStorage.setItem('loginStatus', 'true')
                window.localStorage.setItem("token", data.token)
                window.localStorage.setItem('phone', data.phone)
                // setSignIn(true);
                setWrongOTP(false)
            }
            else{
                setSignIn(false);
                setWrongOTP(true)
                // alert('Wrong OTP')
            }
            })
          .catch(error => {
            setSignIn(false);
            alert('System Error.Contact Admin')
            console.log(error)
        })
      };

      useEffect (()=>{
        if(source){
          const handleSubmitEmail = () => {
            // Send the formData to the API or perform further processing here
          console.log('workssubmit')
          fetch(`${process.env.REACT_APP_API_ENDPOINT}/authorise/login/?email=${email}`, {
            method: 'GET',
            headers: {'Content-Type': 'application/json'},
          })
          .then(
              data => data.json(),        )
          .then(
            data => {
              // this.props.userLogin(data.token);
              if(data['otp_access']===true){
                console.log('data=',data['pseudo_email'])
              //   props.userLogin(data['pseudo_email'])
                window.localStorage.setItem("pseudo_email", data['pseudo_email'])
              //   props.setClientID(data['client_id'])
                window.localStorage.setItem("clientID", data['client_id'])
                window.localStorage.setItem('unregistered',data['unregistered'])
                window.localStorage.setItem('user_name', data['user_name'])
                if (data["investor_id"]) {
                  window.localStorage.setItem("investor_id", data["investor_id"]);
                }
                if(data['gender_male']!==true){
                  window.localStorage.setItem('gender_male' , 'false')
                }else{
                  window.localStorage.setItem('gender_male' , 'true')
                }
              }else{
                alert('You need to sign in with microsoft or gmail')
              }
            }
          )
          .catch( error => console.error(error))
    
          };
          handleSubmitEmail()
        }
      },[])

    

      if(window.localStorage.getItem('loginStatus')=='true'){
        onUserLoginCT(
          window.localStorage.getItem("user_name") !== undefined
            ? window.localStorage.getItem("user_name")
            : email.split("@")[0],
          email,
          window.localStorage.getItem("gender_male") === "true" ? "M" : "F",
          window.localStorage.getItem("clientID")
        );
        if(source){
          if (
            newsletter_name &&
            newsletter_month &&
            newsletter_user &&
            newsletter_email
          ) {
            onNewsletterClickCT(
              newsletter_name,
              newsletter_month,
              "clicked",
              newsletter_email,
              "Otp Submit Page"
            );
            window.localStorage.removeItem("newsletter_name");
            window.localStorage.removeItem("newsletter_month");
            window.localStorage.removeItem("newsletter_user");
            window.localStorage.removeItem("newsletter_email");
          }
        }
        // below is always true
        console.log('signIn=',signIn)
        let player_name = window.localStorage.getItem("player_name")
        if (player_name){
          window.localStorage.setItem("ReportName", "Sectors_Company_Profile")
          return <Navigate to = "/report"/>
        }else{
          if(source==='newsletter'){
            navigate('/redirect/?source=newsletter')
          }else{
            navigate('/redirect')
          }
         
        }
    }

      return (
        <SignIndiv>
          <RHSdiv>
            <Benchmarksdiv>
              <Logo src="/Images/BenchmarksNew.svg" alt="logo" />
              <ByRedseerDiv>
                <ByRedseerLogo src="/Images/byredseer.png" alt="byredseer" />
              </ByRedseerDiv>
            </Benchmarksdiv>
            <Signupmiddiv>
              An OTP has been sent to your registered email id.
            </Signupmiddiv>
            {wrongOTP ? <ErrorText>Wrong OTP</ErrorText> : <div></div>}
            <FormDiv>
              <StyledForm onSubmit={(e) => handleSubmit(e)}>
                <CustomForm fields={fields} onChange={handleChange} />
                <StyledButton type="submit">Submit</StyledButton>
              </StyledForm>
            </FormDiv>
          </RHSdiv>
        </SignIndiv>
      );
    };
    
    export default Otp3;
    
    const SignIndiv = styled.div`
      display: flex;
      margin-left: 13%;
    `;
    
    const RHSdiv = styled.div`
      width: 100%;
      @media (min-width: 768px) {
        width: 70vw;
      }
    `;
    
    const Logo = styled.img`
      height: 30px;
    `;
    
    const Benchmarksdiv = styled.div`
      margin-top: 15vh;
      padding: 2%;
      display: flex;
      flex-direction: column;
      align-items: center;
      ${media.small`
        margin-top: 25vh;
      `}
    `;
    
    const Signupmiddiv = styled.div`
      padding: 20px;
      text-align: center;
      font-size: 20px;
      ${media.small`
        font-size: 12px;
        padding: 0;
      `}
    `;
    
    const ErrorText = styled.div`
      color: red;
      text-align: center;
    `;
    
    const FormDiv = styled.div`
      display: flex;
      justify-content: center;
    `;
    
    const StyledForm = styled.form`
      width: 30%;
    `;
    
    const StyledButton = styled.button`
      width: 60%;
      margin-left: 20%;
      background-color: #0099fe;
      line-height: 40px;
      border-radius: 50px;
      border: none;
      margin-top: 20px;
      color: white;
      ${media.small`
        font-size:12px;
              line-height: 20px;
      margin-top: 5px;

        `}
    `;
    
    const ByRedseerDiv = styled.div`
      display: flex;
      margin-left: 170px;
    `;
    
    const ByRedseerLogo = styled.img`
      height: 20px;
    `;
    