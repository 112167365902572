// userService.js
import api from './api';

export const getUser = (id) => {
  return api.get(`/users/${id}`);
}

export const updateUser = (id, data) => {
  return api.put(`/users/${id}`, data);
}

export const newreportaccess = (client_id) =>{
  return api.get(`/newreportaccess/?client_id=${client_id}`);
}

export const getrevpool= (industry_id) =>{
  return api.get(`/revpool/?industry_id=${industry_id}`);
}

export const getDownloadsClientExcel=(client_id)=>{
  return api.get(`/client-excels/?client_id=${client_id}`);
}