import './App.css';
import React, { useState, useEffect, useRef } from 'react';
import {BrowserRouter as Router, Routes, Route, useNavigate } from "react-router-dom";
import Otp from './pages/Otp';
import Mainpage from './pages/Mainpage';
import Report from './pages/Report';
import FigmaLogin from './pages/FigmaLogin';
import FigmaOtp from './pages/FigmaOtp';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module'
import NewFigmaLogin from './pages/NewFigmaLogin';
import NewFigmaSIgnIn from './pages/NewFigmaSIgnIn';
import NewFigmaOTP from './pages/NewFigmaOTP';
import NewReport from './pages/NewReport';
import NewMainPage from './pages/NewMainPage';
import Frontpage from './pages/Frontpage';
import Article from './pages/Article';
import ArticleDetail from './pages/ArticleDetail';
import Searchpage from './pages/Searchpage';
import PowerbiCompany from './pages/PowerbiCompany';
import DjangoEmbed from './pages/DjangoEmbed';
import Internet from './pages/Internet';
import Front from './pages/Front';
import MyProvider from './utils/contexts/MyProvider';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import {store} from './utils/redux/store'
import NewFigmaSignUp from './pages/NewFigmaSignUp';
import ApprovedRequest from './pages/ApprovedRequest';
import Contact from './pages/Contact';
import FrontPage3 from './pages/FrontPage3';
import Industries from './pages/Industries';
import Signup from './pages/Signup';
import SignIn from './pages/SignIn';
import Companies from './pages/Companies';
import Brands from './pages/Brands';
import Otp3 from './pages/Otp3';
import Sub from './pages/Sub';
import Report3 from './pages/Report3';
import Pdf from './pages/Pdf';
import Invitations from './pages/Invitations';
import CompanyBoard from './pages/CompanyBoard';
import RedirectionPage from './pages/RedirectionPage';
import Testing from './pages/Testing';
import clevertap from 'clevertap-web-sdk';
import { CLEVERTAP_ACC_ID, AUTO_LOGOUT_TIME, AUTO_LOGOUT_NEW_TAB_DELAY } from "./constants/constants";
import RevenuePool from './pages/RevenuePool';
import CompanyListing from './pages/CompanyListing';
import Portfolio from './pages/Portfolio';
import AdminConsole from './pages/AdminConsole';
import Logtable from './components/ChangeLogs/logtable';
import InternetLandscape from './pages/InternetLandscape';
import CompanyListingOld from './pages/CompanyListing/backup';
import TermsAndConditions from './pages/TermsAndCondition';
import PrivacyPolicy from './pages/PrivacyPolicy';
import MyDownloads from './pages/MyDownloads';

const theme = {}; // Define theme if needed

const AllRoutes = React.memo(() => {
  let [Email, setEmail] = useState('');
  let [RealEmail, setRealEmail] = useState('');
  let [Token, setToken] = useState('');
  let [IsAdmin, setIsAdmin] = useState(false)
  let [IsSignedIn, setIsSignedIn] = useState(false)
  let [IsLoggedIn, setIsLoggedIn] = useState(false)
  let [Reportname, setReportname] = useState('')
  let [ReportVersionID, setReportVersionID] = useState('')
  let [clientID, setClientID] = useState(0)

  
  useEffect(() => {
    clevertap.init(CLEVERTAP_ACC_ID);
    console.log("Clevertap - Acc ID -", CLEVERTAP_ACC_ID);
    clevertap.spa = true;
    clevertap.enablePersonalization = true;
    const setUserData = () => {
      clevertap.spa = true;
      clevertap.enablePersonalization = true;
      clevertap.privacy.push({ optOut: false });
      clevertap.privacy.push({ useIP: true });

      // if (navigator.geolocation) {
      //   navigator.geolocation.getCurrentPosition(
      //     (position) => {
      //       clevertap.privacy.push({ optOut: false });
      //       clevertap.privacy.push({ useIP: true });
      //     },
      //     (error) => {
      //       clevertap.privacy.push({ optOut: false });
      //       clevertap.privacy.push({ useIP: false });
      //     }
      //   );
      // } else {
      //   console.log("Error - Geolocation is not supported by this browser.");
      // }
    };
    const timeoutId = setTimeout(() => {
      setUserData();
    }, 500);
    return () => clearTimeout(timeoutId);
  }, []);


  let userLogin = (email) => {
    setIsLoggedIn(true);
    setEmail(email)
    // setPseudoEmail(pseudo_email)
  }

  let getRealEmail=(real_email)=>{
    setRealEmail(real_email);
  }

  let getReportName = (repname) =>{
    setReportname(repname);
    console.log('reportnameapp = ', Reportname )
  }

  let getReportVersionID = (id) => {
    setReportVersionID(id)
  }

  let SignedInStatus = (status) => {
    setIsSignedIn(status)
  }

  let setTokenVal = (Token)=>{
    setToken(Token)
  }
  
  let setClientId = (clientID)=>{
    setClientID(clientID)
  }

  // prod  tracking id ga
  // const TRACKING_ID = 'UA-241888110-1'

// local tracking id
  const TRACKING_ID = 'UA-241614253-1'

  // prod args
//   const tagManagerArgs = {
//     gtmId: 'GTM-MXCJ6SF'
// }

// testting args
const tagManagerArgs = {
  gtmId: 'GTM-P3W82CC'
}
  TagManager.initialize(tagManagerArgs)

  // react ga prod
  // ReactGA.initialize(TRACKING_ID)


  return (
    <Routes>
      <Route path = "/signin" element = {<FigmaLogin userLogin={userLogin} getRealEmail={getRealEmail} setClientID={setClientID}/>}></Route>
      <Route path = "/signup3" element = {<NewFigmaSignUp userLogin={userLogin} getRealEmail={getRealEmail} setClientID={setClientID}/>}></Route>
      {/* <Route path = '/login' element = {<NewFigmaLogin/>}></Route> */}
      <Route path = '/signin4' element={<NewFigmaSIgnIn/>}  ></Route>
      <Route path = '/otp4' element = {<NewFigmaOTP/>}></Route>
      <Route path = '/frontpage' element = {<Front/>}></Route>
      {/* <Route path = "/otp" element = {<Otp Token={Token} getReportName={getReportName}/>}/> */}
      {/* <Route path = '/signup' element = {<Front/>}></Route> */}
      <Route path = "/FigmaOtp" element = {<FigmaOtp email = {RealEmail} IsLoggedIn={IsLoggedIn} SignedInStatus={SignedInStatus} setTokenVal = {setTokenVal}/>}/>
      {/* <Route path = "/mainpage" element = {<Mainpage Email = {'maruti@redseerconsulting.com'} Token={Token} Reportname={Reportname} IsAdmin = {IsAdmin} getReportVersionID = {getReportVersionID}/>}/> */}
      {/* <Route path = "/mainpage" element = {<Mainpage email = {RealEmail} pseudo_email={Email} IsSignedIn={IsSignedIn} Token = {Token} getReportName={getReportName} clientID={clientID}/>}/> */}
      <Route path = "/report" element = {<Report Token={Token} email = {RealEmail} pseudo_email={Email} ReportName = {Reportname}/>}/>
      {/* <Route path = 'newreport' element = {<NewReport/>}></Route> */}
      <Route path = '/newmainpage' element = {<NewMainPage/>}></Route>
      <Route path = '/search' element = {<Searchpage/>}></Route>
      <Route path = '/article' element = {<Article/>}></Route>
      <Route path='/articled' element = {<ArticleDetail/>}></Route>
      <Route path = '/powerbicompany' element = {<PowerbiCompany/>}></Route>
      <Route path = '/djangoembed' element = {<DjangoEmbed/>}></Route>
      <Route path = '/approvedrequest' element = {<ApprovedRequest/>}></Route>
      <Route path = '/contact' element = {<Contact/>}></Route>
      <Route path='/' element = {<SignIn/>}></Route>
      <Route path='/industries' element = {<Industries/>}></Route>
      <Route path='/signup' element = {<Signup/>}></Route>
      <Route path='/signin3' element = {<SignIn/>}></Route>
      <Route path = '/companies' element = {<Companies/>}></Route>
      <Route path = '/brands' element = {<Brands/>}></Route>
      <Route path = '/otp' element = {<Otp3/>}></Route>
      <Route path = '/Sub3' element = {<Sub/>}></Route>
      <Route path = '/Report3' element={<Report3/>}></Route>
      <Route path = '/pdf' element={<Pdf/>}></Route>
      <Route path = '/invitations' element={<Invitations/>}></Route>
      <Route path = '/redirect' element={<RedirectionPage/>}></Route>
      <Route path = '/company-board' element={<CompanyBoard/>}></Route>
      <Route path = '/revpool' element={<RevenuePool/>}></Route>
      <Route path = '/company-listing-new' element={<CompanyListing/>}></Route>
      <Route path = '/company-listing' element={<CompanyListingOld/>}></Route>
      <Route path = '/portfolio' element={<Portfolio/>}></Route>
      <Route path = '/changeLogs' element={<Logtable/>}></Route>
      <Route path = '/admin-console' element={<AdminConsole/>}></Route>
      <Route path = '/internet-landscape' element={<InternetLandscape/>}></Route>
      <Route path="/terms-of-use" element={<TermsAndConditions />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/downloads" element={<MyDownloads />} />
    </Routes>
  )
})

const MainApp = () => {
  const timeoutRef = useRef(null);
  const navigate = useNavigate();
  const loginStatus = window.localStorage.getItem("loginStatus");
  const MAX_TIMEOUT = 2147483647;


  let handleSignOut = () => {
    if (window.localStorage.getItem("loginStatus") === "true") {
      console.log("Inactive session, logging out ...");
      let prop_email = window.localStorage.getItem("email");
      let prop_token = window.localStorage.getItem("token");
      fetch(`${process.env.REACT_APP_API_ENDPOINT}/logout/?email=${prop_email}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${prop_token}`,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          console.log("logout successful");
        })
        .catch((error) => {
          console.error(error);
        });
      localStorage.clear();
      // navigate("/");
    } else {
      console.log("Inactive session, already logged out.");
      // navigate("/");
    }
  };

  const resetLogoutTimer = (delay) => {
    if (window.localStorage.getItem("loginStatus") === "true" && delay) {
      clearTimeout(timeoutRef.current);
  
      if (delay > MAX_TIMEOUT) {
        timeoutRef.current = setTimeout(() => {
          resetLogoutTimer(delay - MAX_TIMEOUT); 
        }, MAX_TIMEOUT);
      } else {
        timeoutRef.current = setTimeout(() => {
          checkAllTabActivity();
        }, delay);
      }
    }
  };

  const checkAllTabActivity = () => {
    const userLastActivity = Number(window.localStorage.getItem("userLastActivity"));
    const inactiveSessionTime = Date.now() - userLastActivity;
    if (inactiveSessionTime >= AUTO_LOGOUT_TIME) {
      handleSignOut();
    } else {
      resetLogoutTimer(AUTO_LOGOUT_TIME-inactiveSessionTime + AUTO_LOGOUT_NEW_TAB_DELAY);
    }
  };

  const handleUserActivity = () => {
    if (window.localStorage.getItem("loginStatus") === "true"){
      const userLastActivity = Date.now();
      window.localStorage.setItem("userLastActivity", userLastActivity);
      resetLogoutTimer(AUTO_LOGOUT_TIME);
    }
  };

  const firstTimeWebsiteLoad = () => {
    if (!window.localStorage.getItem("forceSignOut")) {
      console.log("Force sign-out triggered.");
      localStorage.clear();
      window.localStorage.setItem("forceSignOut", "true"); // Reset the flag
      navigate("/");
    } else {
      const userLastActivity = window.localStorage.getItem("userLastActivity");
      if (userLastActivity) {
        const inactiveSessionTime = Date.now() - userLastActivity;
        if (inactiveSessionTime >= AUTO_LOGOUT_TIME) {
          handleSignOut();
        }
      } else {
        resetLogoutTimer(AUTO_LOGOUT_TIME);
      }
    }
  };

  useEffect(() => {
    // Reset logout session for first-time website login
    firstTimeWebsiteLoad()

    // Reset logout session after user interacts with the website
    window.addEventListener("mousemove", handleUserActivity);
    window.addEventListener("keydown", handleUserActivity);
    window.addEventListener("click", handleUserActivity);
    window.addEventListener("scroll", handleUserActivity);

    return () => {
      window.removeEventListener("mousemove", handleUserActivity);
      window.removeEventListener("keydown", handleUserActivity);
      window.removeEventListener("click", handleUserActivity);
      window.removeEventListener("scroll", handleUserActivity);
      clearTimeout(timeoutRef.current);
    };
  }, [loginStatus]);

  return <AllRoutes />;
};

function App() {
  return (
    <Provider store = {store}>
      <ThemeProvider theme={theme}>
        <MyProvider>
          <Router>
            <MainApp/>
          </Router>
        </MyProvider>
      </ThemeProvider>
    </Provider>

  );
}

export default App;
