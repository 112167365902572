import styled from "styled-components";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { media } from "../../utils/media";
const PrivacyPolicy = () => {
  return (
    <PageWrapper>
      <Header />
      <DataSection>
        <ContentContainer>
          <h1>Privacy Policy</h1>
          <div><b>Effective Date:</b>February 3,2025</div>
          <div>
          The web platform <a href="https://benchmarks.digital/" target="blank">https://benchmarks.digital/</a>  <b>(“Platform”)</b> is owned and operated by Redseer Strategy Consultants Private Limited, having its registered office at Prestige Obelisk, no 3, 5th Floor Kasturba Road, Mahatma Gandhi Road, Bangalore, India - 560001 <b>(“Company”, “we” or “us” or “our”)</b>. 
          </div>
          <div>
          Any reference of “you”, “your”, or “user” in this  privacy policy would mean the person who accesses and/or uses the Platform, in any manner whatsoever.
          </div>
          <div>
          The Company provides certain market research and intelligence insights and related data to its customers in India through the Platform (“Services”). The privacy policy sets out the privacy practices of the Company with respect to the entire content of the Platform and is designed to assist you in understanding how we deal with maintain your privacy while you use this Platform <b>(“Privacy Policy”)</b>.
          </div>
          <div>
          Please read these Privacy Policy, along with the terms of use available at <a href={`https://benchmarks.digital/terms-of-use/`} target="blank" style={{ textDecoration: "none" }}>
           <b>(“Terms of Use”)</b>
         </a> and all other rules and policies made available or published on the Platform as they shall govern your use of the Platform, and the Services provided thereunder.
          </div>
          <div>
          By using or visiting the Platform, you signify your agreement to these Privacy Policy and the Terms of Use. By downloading, registering, signing up for the Platform or otherwise accessing, receiving, or using the Platform, you acknowledge that you have read, understood, and consented to be governed and bound by these Privacy Policy and the Terms of Use. If you do not accept or agree to any part of these Privacy Policy and the Terms of Use, please do not use the Platform.
          </div>
          <div>
          The Privacy Policy is an electronic record as per the Information Technology Act, 2000 (as amended/re-enacted) and rules thereunder<b>(“IT Act")</b> This electronic record is generated by a computer system and does not require any physical or digital signature. 
          </div>
          <h2>1.PERSONAL INFORMATION WE COLLECT</h2>
          <p>
          To the extent allowed by law, we are the sole owners of the Personal Information <i>(defined below)</i> collected through the Platform. To the extent we are not allowed to own the Personal Information under law, you hereby grant us a perpetual, royalty free and irrevocable license to use the Personal Information on the terms and conditions contained herein. We may collect and process the Personal Information provided by you, including but not limited to:
          </p>
          <div><b>A.Personal Information You Provide to Us</b></div>
          <p>(i) <b>Account Information:</b> When you sign up for a freemium or paid
              account, we collect personal information such as your name, email
              address, company name, phone number, and job title.</p>
              <p>(ii) <b>Payment Information:</b>      For paid subscriptions, we collect billing details, including payment method and billing address, through secure payment gateways.
              It is clarified that in the event you make any payments through the Platform, we will not store any payment or card-related information which you may provide in the course of making such payments, such as card number, account number, validity date, expiry date or CVV number. </p>    
            <div><b>B.Personal Information We Collect Automatically</b></div>
            <p>(i) <b>Usage Data:</b> We collect information about your interactions with
              the Platform, including pages visited, features accessed, and time
              spent on the Platform.</p>
            <p>(ii) <b>Device Data:</b> We collect data about your device, such as IP
            address, browser type, operating system, and language preferences.</p>
            <p>(iii) <b>Cookies and Tracking Technologies:</b> We use cookies and similar
              technologies to enhance user experience and gather analytics. You
              can manage your cookie preferences through your browser settings.</p>
            <div><b>C. Third-Party Data</b></div>
            <p>(i) We may receive data from third-party sources,
              such as publicly available datasets, business partners, or
              analytics providers, to enrich our insights and improve services.</p>
           <p>For the purpose of this Privacy Policy, the term “Personal Information” shall mean information collected under 1(A), 1(B) 1(C) as mentioned above or any information that relates to you, which, either directly or indirectly, in combination with other information available or likely to be available with the Company, is capable of identifying you, which is submitted to and/or collected over the Platform and maintained by the Company in an accessible form, provided that, any information that is freely available or accessible in public domain or furnished under the Right to Information Act, 2005 or any other law for the time being in force shall not be regarded as Personal Information.</p>
          <h2>2. HOW WE USE YOUR PERSONAL INFORMATION</h2>
          <p>The primary goal of the Company in collecting Personal Information is to provide you with our Services through our Platform. The Company may use the Personal Information provided by you for the following purposes: </p>
          <p>(i) <b>To Provide and Improve Services:</b>Deliver insights, data, and reports based on your subscription preferences.</p>
          <p>(ii) <b>To Communicate with You: </b>Send account-related updates, service notifications, and marketing communications (you can opt out of marketing emails).</p>
          <p>(iii) <b>To Analyze and Enhance the Platform:</b>Monitor usage patterns to improve the functionality and performance of the Platform.</p>
          <p>(iv) <b>To Enforce Terms and Protect Users:</b> Ensure compliance with our Terms of Use and protect against fraud, unauthorized access, or illegal activities.</p>
          <p>(V) To observe, improve and administer the quality of Service;</p>
          <p>(vi) Remember the basic information provided by you for effective access;</p>
          <p>(vii) To confirm your identity in order to determine your eligibility to use the Platform and avail the Services;</p>
          <p>(viii) To notify you about any changes to the Platform;</p>
          <p>(ix) To enable the Company to comply with its legal and regulatory obligations;</p>
          <p>(x) For the purpose of sending administrative notices and Service-related alerts and similar communication, as detailed under this Privacy Policy, with a view to optimizing the efficiency of the Platform;</p>
          <p>(xi) To enforce the Company’s Terms of Use.</p>
          <p>In accordance with TRAI regulations, please be informed that we may reach out to users registered on the National Do Not Call (DND) registry through calls and SMS for essential communications related to our Services. By using the Platform and/or the Services, you hereby consent to receiving such communications.</p> 
          <h2>3. HOW WE SHARE YOUR PERSONAL INFORMATION</h2>
          <p>
          We do not sell your Personal Information to third parties. However, we may share Personal Information in the following circumstances: 
          </p>
          <p>(i) <b>Sharing</b><br/>The Company may share aggregated demographic information with the Company’s partners or affiliates. This is not linked to any Personal Information that can identify an individual person. The Company will not be liable for transfer of any Personal Information resulting from the loss or distribution of data, the delineation or corruption of media storage, power failures, natural phenomena, riots, act(s) of vandalism, sabotage, terrorism or any other event beyond Company’s reasonable control. Further, the Company’s Privacy Policy does not cover the use of cookies by its partners and affiliates since the Company does not have access to or control over such cookies.</p>
          <p>(ii) <b>Consulting</b><br/>The Company may partner with another party to provide certain specific services if required. When you sign-up for such services, the Company may share such information, including without limitation, names, or other Personal Information that is necessary for the third party to provide these services. The Company’s contractual arrangements with such third parties restrict these parties from using Personal Information except for the explicit purpose of assisting in the provision of these services.</p>
          <p>(iii) <b>Targeted Advertising</b><br/>You expressly acknowledge and agree that we may also share or transfer information in relation to your browsing history, cache, internet protocol address and domain name to third-party service providers, for the limited purpose of allowing or permitting such third-party service providers, including Google, and other social media websites to display advertisements and notifications on websites across the internet, based on the information that is collected by us, to optimize and display advertisements based on your past preferences and visits on the Platform as part of its Services. The Company does not allow any unauthorized persons or organization to use any information that the Company may collect from you through this Platform. </p>
          <p>(iv) <b>Regulatory Disclosures</b><br/>We cooperate with government and law enforcement officials and private parties to enforce and comply with the law. Thus, we may access, use, store, transfer and disclose your Personal Information, to third parties, such as government or law enforcement officials or private parties, if we reasonably determine that it is necessary and appropriate: (i) to satisfy any applicable law, regulation, governmental requests or legal process; (ii) to protect the safety, rights, property or security of the Company, the Services, the Platform or any third party; (iii) to protect the safety of the public for any reason; (iv) to detect, prevent or otherwise address fraud, security or technical issues; and /or (v) to prevent or stop any activity we consider to be, or to pose a risk of being, an illegal, unethical, or legally actionable activity. Such disclosures may be carried out without notice to you.</p>
          <p>(v) <b>Link to Third Party Websites</b><br/>This Platform may contain links which may lead you to other websites. Please note that once you leave the Platform, you will be subjected to the privacy policy of other websites. The linked websites are not necessarily under the control of the Company. Please be aware that the Company is not responsible for the privacy practices of such other sites. The Company encourages you to read the privacy policies of each and every website that collects Personal Information. If you decide to access any of the third-party sites linked to the Platform, you do this entirely at your own risk. </p>
          <p>(vi) <b>Log Data</b><br/>In the event of an error on the Platform, we collect data and information (through third parties) on your phone called log data. This log data may include information such as your device’s internet protocol address, device name, operating system version, the configuration of the Platform when utilizing our Service, the time and date of your use of the Service, and other such related statistics.
          </p>

          <h2>4. DATA RETENTION</h2>
          <p>
          We retain your Personal Information for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required by law. If you wish to delete your account or request that we delete your data, please contact us at <a href="mailto:compliance@redseerconsulting.com">
              compliance@redseerconsulting.com
            </a>. However, even after your account is terminated, the Company may retain your Personal Information as needed to comply with our legal and regulatory obligations, resolve disputes, conclude any activities related to cancellation of an account, investigate or prevent fraud and other inappropriate activity, to enforce our agreements, and for other business reasons.
          </p>

          <h2>5. INTERNATIONAL USERS</h2>
          <p>
          While our Platform primarily tracks e-commerce and digital platform performance in India, users from anywhere in the world can access it. If you are accessing the Platform from outside India, please note that your data may be transferred to, processed, and stored in India or other jurisdictions where we or our partners/affiliates operate. By using the Platform, you consent to such data transfers.
          </p>

          <h2>6. DATA SECURITY</h2>
          <p>
          The Company has put in place necessary security practices and procedures to safeguard and secure Personal Information. The Company only processes Personal Information in a way that is compatible with and relevant for the purpose for which it was collected or authorized by the user. If a user wishes to access their Personal Information or make corrections or deletions to any inaccurate details, they can contact the Company at compliance@redseerconsulting.com for assistance. The Company uses commercially reasonable precautions to preserve the integrity and security of your information against loss, theft, unauthorised access, disclosure, reproduction, use or amendment. The Personal Information that is collected from you may be transferred to, stored and processed within or outside India. By submitting Personal Information on the Platform, you agree to this transfer, storing and / or processing. However, no data transmission or storage system is 100% secure. If you have concerns about the security of your data, please contact us. 
          </p>
          <p>
          The Company will take such steps as it considers reasonably necessary to ensure that your information is treated securely and in accordance with this Privacy Policy. In using the Platform, you accept the inherent security implications of data transmission over the internet. Accordingly, the use of the Platform will be at your own risk and the Company assumes no liability for any disclosure of Personal Information due to errors in transmission, unauthorised third-party access or other acts of third parties, or acts or omissions beyond its reasonable control and you agree not to hold the Company responsible for any breach of security. In the event the Company becomes aware of any breach of the security of your Personal Information, it will notify you and take appropriate action to the best of its ability to remedy such a breach.
          </p>
          <h2>7. EXCLUSIONS</h2>
          <p>
         (i) This Privacy Policy does not apply to any information other than the Personal Information collected by the Company through the Platform, as outlined in the 'Personal Information We Collect' clause above. This Privacy Policy will not apply to any unsolicited information provided by you through this Platform or through any other means. This includes, but is not limited to, information posted on any public areas of the Platform. All such unsolicited information shall be deemed to be non-confidential, and the Company will be free to use, disclose such unsolicited information without limitation.
          </p>
          <p>
            (ii)The Company also protects your Personal Information, except in cases specifically mentioned in this Privacy Policy. Access to your Personal Information is limited to employees, agents or partners and third parties, who the Company reasonably believes will need Personal Information to enable the Company to provide Services to you. However, the Company is not responsible for the confidentiality, security or distribution of your Personal Information by our partners and third parties outside the scope of our agreement with such partners and third parties. 
          </p>
          <h2>8. YOUR RIGHTS </h2>
          <p>
          You may have the following rights regarding your Personal Information:
          </p>
          <p>(i)<b>Access and Correction:</b> You are responsible for maintaining the accuracy of the information you submit to us, such as your contact information provided during account registration. The Company relies on the users to disclose to it all relevant and accurate information and to inform the Company of any changes. If you wish to make a request to correct or update any of your Personal Information which we hold about you, you may submit your request on the email address mentioned below.</p>
          <p>(ii)<b>Data Deletion:</b> You may request deletion of your account at any time. Following an account deletion request, we will delete your account and Personal Information, unless it is required to be retained due to legal or regulatory requirements. Please note that we may take time to process deletion requests, and after the account is deleted, we will not use your Personal Information for any processing unless required by us to comply with our legal obligations in accordance with this Privacy Policy.</p>
          <p>(iii)<b>Withdraw Consent:</b> The consent that you provide for the collection, use and disclosure of your Personal Information will remain valid until such time it is withdrawn by you in writing. If you withdraw your consent, the Company will stop processing the relevant Personal Information, except to the extent that the Company has other grounds for processing such Personal Information under applicable laws. The Company will respond to your request within a reasonable timeframe. You may withdraw your consent at any time by contacting the Company at the below-mentioned email address. </p>
          <p>(iv)<b>Opt-Out:</b>The Platform provides an option to opt-out of receiving any promotional or marketing communications from us. If you do not wish to receive any promotional or marketing communications from us, you can contact us at the address provided below.</p>
          <p>(v)<b>Data Portability: </b>Request a copy of your Personal Information in a structured, machine-readable format. </p>
          <p>To exercise any of these rights, contact us at <a href="mailto:compliance@redseerconsulting.com">
              compliance@redseerconsulting.com
            </a></p>
          <h2>9.CHILDREN'S POLICY</h2>
          <p>
          Our Platform is not intended for children under the age of 18 (eighteen) <b>(“Minor”)</b>, and we do not knowingly collect Personal Information from Minor. If we become aware that we have collected data from a Minor, we will delete it promptly.
          </p>

          <h2>10.CHANGES TO THIS PRIVACY POLICY</h2>
          <p>
          We may update this Privacy Policy from time to time. Any changes will be posted on this page with the updated "Effective Date." We encourage you to review this page periodically to stay informed about how we protect your Personal Information.
          </p>
          <h2>11. GRIEVANCE OFFICER </h2>
          <p>
          In accordance with the Information Technology Act, 2000 and the rules made thereunder, the name and contact details of the grievance officer are provided below. We will use reasonable efforts to respond promptly to requests, questions or concerns you may have regarding our use of Personal Information about you. Except where required by law, the Company cannot ensure a response to questions or comments regarding topics unrelated to this Privacy Policy or the Company’s privacy practices.
          </p>
          <p>
          <b>Name:</b> Kailash Iyer<br/>
          <b>Contact Number:</b> 9686111429<br/>
          <b>Email Id:</b>  <a href="mailto:compliance@redseerconsulting.com">
              compliance@redseerconsulting.com
            </a>
          </p>
        </ContentContainer>
      </DataSection>
      <Footer />
    </PageWrapper>
  );
};

const PageWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #eef9ff;
`;

const DataSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 3.5vw 0px;
  margin-bottom: 10px;
`;

const ContentContainer = styled.div`
  background: #fff;
  padding: 40px;
  border-radius: 8px;
  h1 {
    font-size: 22px;
    font-weight: 600;
    ${media.small`
      font-size: 20px;  
    `}
  }

  h2 {
    font-size: 16px;
    color: #333;
    font-weight: 600;
     ${media.small`
      font-size: 14px;  
    `}
  }

  section {
    margin-bottom: 30px;
  }
    div{
  font-size: 14px;
    line-height: 1.8;
    color: #444;
    margin-bottom: 7px;
  }
  p {
    font-size: 14px;
    line-height: 1.8;
    color: #444;
    margin-bottom: 7px;
    margin-left: 33px;
    &:last-child {
      margin-bottom: 0;
    }
       ${media.small`
      font-size: 12px;  
    `}
  }
`;

export default PrivacyPolicy;
